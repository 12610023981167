import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PageHeader from '../PageHeader'
import { TextField, Checkbox, FormControlLabel, Button, Card, CardContent, Typography, Box, CardMedia } from '@mui/material'
import { ShopListService } from '../../services/shopListService'
import toast from 'react-hot-toast'
import { display } from '@mui/system'
import styled from '@emotion/styled'
import ShopListEdit from './ShopListEdit'
import { set } from 'date-fns'

const ScrollContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  gap: '16px',
  padding: '16px',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
})

const ShopList = () => {
  const { groupId } = useParams()
  const [products, setProducts] = useState([])
  const [editProduct, setEditProduct] = useState(null)
  const [formData, setFormData] = useState({
    title: '',
    price: '',
    sale: false,
    url: '',
    imagePayload: null,
    preview: '',
    encoding: '',
    name: '',
    displayWeight: 0
  })

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      setFormData((prev) => ({
        ...prev,
        imagePayload: {
          imageString: reader.result.split(',')[1],
          encoding: 'base64',
          name: file.name
        }
      }))
    }
    reader.readAsDataURL(file)
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setFormData((prev) => ({
        ...prev,
        price: parseFloat(prev.price)
      }))
      await ShopListService.createNewShopItem({ groupId, data: formData })
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong.')
    } finally {
      toast.success('Shop item created successfully!')
    }
  }

  const getShopItems = async () => {
    try {
      const shopItems = await ShopListService.getShopItems(groupId)
      setProducts(shopItems.shopitems)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getShopItems()
  }, [groupId])

  return (
    editProduct
      ? <ShopListEdit product={editProduct} setEditProduct={setEditProduct} />
      : <div>
        <PageHeader title='Shop List' hideSubgroupChip />
        <Card sx={{ maxWidth: 500, mx: 'auto', p: 3, boxShadow: 3, borderRadius: 2 }}>
          <CardContent>
            <Typography variant='h6' gutterBottom>
              Product Form
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label='Title'
                name='title'
                value={formData.title}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />
              <TextField
                label='Price'
                name='price'
                type='number'
                value={formData.price}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />
              <FormControlLabel
                control={<Checkbox checked={formData.onsale} onChange={handleChange} name='onsale' />}
                label='On Sale'
              />
              <TextField
                label='URL'
                name='url'
                type='url'
                value={formData.url}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />

              <TextField
                label='Display Weight'
                name='displayWeight'
                type='displayWeight'
                value={formData.displayWeight}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />
              <Typography variant='body2' sx={{ mt: 2 }}>
                Upload Image
              </Typography>
              <input type='file' accept='image/*' onChange={handleImageUpload} style={{ marginTop: 8 }} />
              {formData.preview && (
                <img
                  src={formData.preview}
                  alt='Preview'
                  style={{ marginTop: 10, width: '100%', height: 160, objectFit: 'cover', borderRadius: 8 }}
                />
              )}
              <Button type='submit' variant='contained' color='primary' fullWidth sx={{ mt: 2 }}>
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
        <CardScroller products={products} setEditProduct={setEditProduct} />
      </div>
  )
}

const ProductCard = ({ product, setEditProduct }) => {
  const onSelect = () => {
    setEditProduct(product)
  }
  return (
    <Card sx={{ width: 250, flex: '0 0 auto' }}>
      <CardMedia component='img' height='140' image={product.imageString} alt={product.title} />
      <CardContent>
        <Typography variant='h6'>{product.title}</Typography>
        <Typography variant='body1' color='text.secondary'>${product.price}</Typography>
        {/* <Button variant='contained' onClick={onSelect} color='primary' sx={{ mt: 1 }} target='_blank'>
          View
        </Button> */}
      </CardContent>
    </Card>
  )
}

const CardScroller = ({ products, setEditProduct }) => {
  return (
    products.length > 0
      ? (
        <ScrollContainer>
          {products.map((product, index) => (
            <ProductCard key={index} product={product} setEditProduct={setEditProduct} />
          ))}
        </ScrollContainer>
        )
      : (
        <h2 className='text-center my-5'>No products found</h2>
        )
  )
}

export default ShopList
