import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import iconParticipation from '../../resources/challenges-participation.png'
import iconWinner from '../../resources/challenges-winner.png'
import iconStreak from '../../resources/challenges-streak.png'
import ReviewStep from '../templates/ReviewStep'
import { getCached, useGroup } from '../global/groupInfo'

const styles = () => ({
  cell: {
    maxWidth: '30em',
    padding: '1em',
    margin: '1em auto',
    borderRadius: '.25em',
    background: '#d0eaf1',
    textAlign: 'center',
    display: 'flex',
    position: 'relative'
  },
  headerCell: {
    flexDirection: 'column',
    paddingTop: '4em',
    marginTop: '4em',
    '& span': {
      marginBottom: '.5em'
    }
  },
  floatingImgBox: {
    position: 'absolute',
    top: '-3em',
    left: '0',
    right: '0',
    height: '6em',
    display: 'flex',
    justifyContent: 'center'
  },
  floatingImg: {
    width: '6em',
    height: '6em',
    borderRadius: '12em',
    objectFit: 'cover',
    background: '#eeeeee'
  },
  challengeTitle: {
    fontSize: '1.2em'
  },
  trophy: {
    width: '25%',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: '6em',
      height: '6em'
    }
  },
  teamCell: {
    alignItems: 'center'
  },
  teamNum: {
    fontSize: '1.5em',
    marginRight: '.5em'
  }
})

const trophies = {
  participation: { title: 'Participation', icon: iconParticipation },
  winner: { title: 'Winning Team', icon: iconWinner },
  streak: { title: 'Flawless Streak', icon: iconStreak }
}

const notices = [{
  if: challenge => !challenge.name,
  fatal: true,
  warn: true,
  text: 'Enter a name for this challenge.',
  step: 'details'
}, {
  if: challenge => !challenge.description,
  fatal: true,
  warn: true,
  text: 'Enter a description for this challenge.',
  step: 'details'
  // }, {
  //   if: challenge => !challenge.icon && !challenge.currentIcon,
  //   fatal: true,
  //   warn: true,
  //   text: 'Upload an icon for this challenge.',
  //   step: 'details'

}, {
  if: challenge => (challenge.privacy === 'PRIVATE' || (challenge.privacy === 'GROUP' && challenge.joinCodePrefix === 'LDB')) && !challenge.joinCode,
  fatal: true,
  warn: true,
  text: 'This challenge needs a valid join code.',
  step: 'details'
}, {
  if: challenge => challenge.privacy === 'PRIVATE' && challenge.joinCode && challenge.joinCodeValid !== true,
  fatal: true,
  warn: true,
  text: challenge => {
    if (challenge.joinCodeValid === false) return "The join code you entered is invalid; you'll have to choose another."
    if (!challenge.joinCodeValid.unique) return "This join code has already been used; you'll have to choose another."
    if (!challenge.joinCodeValid.requiredLength) return 'Join codes for private challenges must be between 5 and 20 characters.'
    if (!challenge.joinCodeValid.upperCase) return 'Join codes for private challenges must be in all uppercase.'
    if (!challenge.joinCodeValid.validCharacters) return "This join code contains prohibited characters; you'll have to choose another."
    return "The join code you entered is invalid; you'll have to choose another."
  },
  step: 'details'

}, {
  if: challenge => challenge.goal === 'DATE' && challenge.teams.some(team => team.name.trim() === '') && challenge.joinCodePrefix !== 'LDB',
  fatal: true,
  warn: true,
  text: 'One or more teams are still unnamed. Give each team a name to continue.',
  step: 'teams'
}, {
  if: challenge => challenge.goal === 'DATE' && challenge.teams.some((team, i) => (i !== challenge.teams.findIndex(t => t.name === team.name)) && team.name.trim() !== ''),
  fatal: true,
  warn: true,
  text: 'Teams must not have duplicate names.',
  step: 'teams'
}, {
  if: challenge => challenge.removedCurrentTeams,
  warn: true,
  text: challenge => `Some people may have already joined the ${challenge.removedCurrentTeams.length === 1 ? 'team' : 'teams'} you're removing (${challenge.removedCurrentTeams.join(', ')}). They will be removed from the challenge and need to manually join again to participate.`

}, {
  if: challenge => challenge.useNotifs && Object.values(challenge.notifs).some(notif => notif.text.trim() === ''),
  fatal: true,
  warn: true,
  text: 'One or more notifications is blank. Make sure all notifications have valid text to continue.',
  step: 'notifs'

}, {
  if: challenge => challenge.startDate.diff(moment(), 'hours') <= 13,
  fatal: true,
  warn: true,
  text: "It's too close to this challenge's start date to create it now.",
  step: 'schedule'

}, {
  if: challenge => challenge.visibility === 'APPROVED',
  text: 'This edited challenge will need to be re-approved by a publisher once you save it.'
}]

const EditStepReview = ({ data: challenge, classes, ...props }) => {
  const groupInfo = useGroup()
  const targetGroupInfo = getCached(challenge.targetGroup === 'ROOT' ? groupInfo.root.id : challenge.targetGroup)
  if (!targetGroupInfo.name) {
    targetGroupInfo.name = 'group'
  }

  return (
    <ReviewStep
      objTerm='challenge'
      data={challenge}
      notices={notices}
      {...props}
    >
      <div className={classes.cell + ' ' + classes.headerCell}>
        <div className={classes.floatingImgBox}>
          {challenge.icon
            ? <img className={classes.floatingImg} src={challenge.icon.imageString} alt='Challenge icon' />
            : challenge.currentIcon
              ? <img className={classes.floatingImg} src={challenge.currentIcon} alt='Challenge icon' />
              : <img className={classes.floatingImg} src='https://hidrate-images.s3.amazonaws.com/challengeIcons/droplet_1.jpg' alt='Challenge icon' />}
        </div>
        <span className={classes.challengeTitle}>
          {challenge.name || <i>Challenge name</i>}
        </span>
        <span>{challenge.description || <i>Challenge description</i>}</span>
        <span>
          {challenge.privacy === 'PRIVATE' || challenge.joinCodePrefix === 'LDB'
            ? `Joinable with code ${challenge.joinCode || '?????'}`
            : challenge.targetGroup === 'ROOT'
              ? `Joinable by any ${targetGroupInfo.name} member`
              : `Joinable by ${targetGroupInfo.name} subgroup members`}
        </span>
        {challenge.privacy !== 'PRIVATE' &&
          <span>Shown in app {challenge.visibilityDate ? `on ${challenge.visibilityDate.format('MMM D')}` : 'after approval'}</span>}
        <span>Challenge {challenge.goal === 'DATE' ? `runs ${challenge.startDate.format('MMM D')}—${challenge.endDate.format('MMM D')}` : `starts ${challenge.startDate.format('MMM D')}`}</span>
      </div>
      <div className={classes.cell + ' ' + classes.trophyCell}>
        {challenge.trophies.map(trophyObj => {
          const trophy = trophies[trophyObj.trophyType] || { title: 'Unknown trophy' }
          return (
            <div key={trophyObj.trophyType} className={classes.trophy}>
              <img src={trophy.icon} alt='' />
              <span>{trophy.title}</span>
            </div>
          )
        })}
        {challenge.trophies.length === 0 &&
          <>No trophies</>}
      </div>
      {challenge.goal === 'DATE' && challenge.joinCodePrefix !== 'LDB' && challenge.teams.map((team, i) => (
        <div key={team.id} className={classes.cell + ' ' + classes.teamCell}>
          <span className={classes.teamNum}>{i + 1}</span>
          <span className={classes.teamName}>{team.name || <i>Unnamed Team</i>}</span>
        </div>
      ))}
      {challenge.useNotifs &&
        <div className={classes.cell}>
          <span>Notifications enabled</span>
        </div>}
      {challenge.image &&
        <div className={classes.cell}>
          <img src={challenge.image.imageString} alt='' className={classes.challengeImage} />
        </div>}
    </ReviewStep>
  )
}

EditStepReview.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export default withStyles(styles)(EditStepReview)
