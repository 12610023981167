import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import moment from 'moment-timezone'
import { formatVol } from '../global/formatter'

import sipRecordedIcon from '../../resources/sip-recorded.svg'
import sipManualIcon from '../../resources/sip-manual.svg'
import defaultWaterIcon from '../../resources/water.png'

const styles = () => ({
  root: {
    height: '100%',
    position: 'relative',
    overflow: 'auto'
  },
  line: {
    top: '0',
    bottom: '0',
    left: '30px', // list padding + item gutter + (icon width / 2)
    border: '4px solid #cfcfcf',
    borderRadius: '4px',
    background: '#cfcfcf',
    width: '0',
    position: 'absolute'
  },
  sipIcon: {
    width: '36px',
    marginRight: '5%'
  },
  item: {
    position: 'relative',
    '& *': {
      zIndex: '2'
    }
  },
  lineCover: {
    background: 'white',
    position: 'absolute',
    left: '0',
    right: '0',
    zIndex: '1',
    '&.top': {
      top: '0',
      height: '50%'
    },
    '&.bottom': {
      bottom: '0',
      height: '75%'
    }
  }
})

// Utility function to get localized name
const getLocalizedName = (sip, locale = 'en_US') => {
  // Check if localeNames exists and has the requested locale
  if (
    sip?.liquidTypeInfo?.liquidType?.localeNames &&
    sip?.liquidTypeInfo?.liquidType?.localeNames[locale]
  ) {
    return sip.liquidTypeInfo.liquidType.localeNames[locale]
  }

  // Fallback to displayName
  return sip?.liquidTypeInfo?.displayName
}

// Utility function to convert browser locale to our format
const getBrowserLocale = () => {
  let locale = 'en_US'
  try {
  // Get browser language (returns format like 'en-US' or 'fr-FR')
    const browserLocale = navigator.language

    // Convert from 'en-US' to 'en_US' format
    locale = browserLocale.replace('-', '_')
  } catch (err) {
    console.log(err)
  }
  return locale
}

const renderSip = (sip, i, len, units, classes) => {
  let liquidImage = sip?.liquidTypeInfo?.liquidType?.image

  if (!liquidImage) {
    liquidImage = defaultWaterIcon
  } else {
    liquidImage =
      'https://hidrate-images.s3.us-east-1.amazonaws.com/liquidTypes/PROD' +
      liquidImage
  }
  let liquidName = getLocalizedName(sip, getBrowserLocale())
  if (!liquidName) {
    liquidName = 'Water'
  }
  // const total = Math.round((sip.amount / 29.574) * 10) / 10 || 0
  const time =
    sip && sip.time ? moment(sip.time.iso).format('hh:mm A') : '00:00'
  return (
    <ListItem key={sip.objectId} className={classes.item}>
      {i === 0 && <div className={classes.lineCover + ' top'} />}
      {i === len - 1 && <div className={classes.lineCover + ' bottom'} />}
      <img
        className={classes.sipIcon}
        src={!sip.start ? liquidImage : liquidImage}
        alt=''
      />
      <ListItemText
        primary={formatVol(sip.amount, units)}
        primaryTypographyProps={{ variant: 'h4' }}
        secondary={`${
          !sip.start ? 'Manual' : 'Bottle'
        } • ${time} • ${liquidName}`}
        secondaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )
}

const SipHistory = ({ classes, sips, units }) => {
  console.log(sips)
  // sort sips.time.iso descending
  // const sortedSips = sips.sort((a, b) => b.time.iso - a.time.iso)
  return (
    <div className='flex-none'>
      <List disablePadding dense>
        {sips?.length > 0 && <div className={classes.line} />}
        {sips.length
          ? (
              sips
                .sort((a, b) => moment(a.time.iso) - moment(b.time.iso))
                .map((sip, i) => renderSip(sip, i, sips.length, units, classes))
            )
          : (
            <p>No Sips Recorded</p>
            )}
      </List>
    </div>
  )
}

SipHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  sips: PropTypes.array.isRequired,
  units: PropTypes.any
}

export default withStyles(styles, { withTheme: true })(SipHistory)
