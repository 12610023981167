import React, { useEffect, useState } from 'react'
import EditStepDetails from './EditStepDetails'
import EditStepNotifs from './EditStepNotifs'
import EditStepType from './EditStepType'
import EditStepSchedule from './EditStepSchedule'
import EditStepTeams from './EditStepTeams'
import EditStepTrophies from './EditStepTrophies'
import EditStepReview from './EditStepReview'
import StepFlow from '../templates/StepFlow'
import moment from 'moment'
import { challengesService } from '../../services/challengesService'
import { useGroup } from '../global/groupInfo'
import { useNavigate, useParams } from 'react-router-dom'
import ReactGA from 'react-ga4'

const steps = [{
  component: EditStepDetails,
  id: 'details',
  title: 'Details',
  shortTitle: 'Details',
  buttons: true
}, {
  component: EditStepType,
  id: 'type',
  title: 'Choose a Challenge Type',
  shortTitle: 'Type',
  buttons: true
}, {
  component: EditStepSchedule,
  id: 'schedule',
  title: 'Schedule Challenge',
  shortTitle: 'Schedule',
  buttons: true
}, {
  when: challenge => challenge.goal === 'DATE' && challenge.joinCodePrefix !== 'LDB',
  component: EditStepTeams,
  id: 'teams',
  title: 'Create Teams',
  shortTitle: 'Teams',
  buttons: true
}, {
  component: EditStepTrophies,
  id: 'trophies',
  title: 'Choose Trophies',
  shortTitle: 'Trophies',
  buttons: true
}, {
  component: EditStepNotifs,
  when: challenge => challenge.joinCodePrefix !== 'LDB',
  id: 'notifs',
  title: 'Challenge Notifications',
  shortTitle: 'Notifications',
  buttons: true
}, {
  component: EditStepReview,
  id: 'review',
  shortTitle: 'Submit'
}]

const defaultDate = moment().utc(true).startOf('day').add(12, 'hours')

const ChallengeCreate = () => {
  const groupInfo = useGroup()
  const navigate = useNavigate()
  const { groupId } = useParams
  const [challenge, setChallenge] = useState({
    name: '',
    description: '',
    icon: null,
    privacy: 'GROUP', // PUBLIC, GROUP, PRIVATE
    targetGroup: groupInfo.hasParent ? groupInfo.id : 'ROOT',
    joinCode: '',
    joinCodePrefix: '',
    goal: 'DATE', // THRESHOLD, DATE
    metric: 'POINT', // POINT, BOTTLES
    thresholdValue: 1000,
    visibilityDate: null,
    startDate: moment(defaultDate).add(7, 'days'),
    endDate: moment(defaultDate).add(7, 'days').add(1, 'month'),
    teams: [{ name: '', id: '__0' }, { name: '', id: '__1' }],
    trophies: ['participation', 'winner', 'streak'].map(id => ({ trophyType: id })),
    notifs: [],
    useNotifs: false,
    image: null
    // reviewRequired: true // enable features and notices referring to the review process
  })

  const modifyChallenge = (obj) => {
    setChallenge(challenge => ({ ...challenge, ...obj }))
  }

  useEffect(() => {
    console.log(window.location.pathname)
    ReactGA.send({
      hitType: 'pageview',
      page: window?.location?.pathname ?? '',
      title: 'Challenge Create Page'
    })
  }, [])

  useEffect(() => {
    if (!challenge.joinCode) {
      modifyChallenge({ joinCodeValid: false })
      return
    }
    challengesService.validateJoinCode({ groupId, joinCode: challenge.joinCode }).then(res => {
      if (Object.values(res).some(v => v === false)) {
        modifyChallenge({ joinCodeValid: res })
      } else {
        modifyChallenge({ joinCodeValid: true })
      }
    })
  }, [groupId, challenge.joinCode])

  const submitChallenge = async () => {
    const targetGroupId = challenge.targetGroup === 'ROOT' ? groupInfo.root.id : challenge.targetGroup

    const res = await challengesService.createChallenge({
      ...challenge,
      groupId: targetGroupId,
      teams: challenge.goal === 'DATE' && challenge.joinCodePrefix !== 'LDB' ? challenge.teams : undefined,
      visibilityDate: challenge.privacy !== 'PRIVATE' ? (challenge.visibilityDate || moment()) : undefined,
      endDate: challenge.goal === 'DATE' ? challenge.endDate : undefined,
      thresholdValue: challenge.goal === 'THRESHOLD' ? challenge.thresholdValue : undefined,
      joinCode: challenge.privacy === 'PRIVATE' || challenge.joinCodePrefix === 'LDB' ? challenge.joinCode : undefined,
      joinCodePrefix: challenge.joinCodePrefix,
      notifs: undefined,
      useNotifs: undefined
    })

    if (!challenge.useNotifs) return

    const teamMap = {}
    const newTeams = res.challenge.teams
    challenge.teams.forEach(oldTeam => {
      const newTeam = newTeams.find(t => t.name === oldTeam.name)
      teamMap[oldTeam.id] = newTeam
    })

    const notifs = Object.values(challenge.notifs).map(notif => ({
      searchKey: notif.type,
      text: notif.text,
      challengeTeam: teamMap[notif.team || '__none']
    }))

    console.log({ teamMap, notifs })

    await challengesService.createChallengeNotifs(targetGroupId, res.challenge.challengeId, notifs)
  }

  const cancel = () => {
    navigate(-1)
  }

  return (
    <StepFlow
      title='Create a Challenge'
      subtitleFunc={step => step !== 0 ? challenge.name : ''}
      allSteps={steps}
      data={challenge}
      modifyData={modifyChallenge}
      onSubmit={submitChallenge}
      onCancel={cancel}
      {...{ groupId }}
    />
  )
}

export default ChallengeCreate
