import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TextField, Checkbox, FormControlLabel, Button, Card, CardContent, Typography } from '@mui/material'
import { ShopListService } from '../../services/shopListService'
import toast from 'react-hot-toast'
import PageHeader from '../PageHeader'
import { display } from '@mui/system'
import styled from '@emotion/styled'

const EditProductForm = ({ product, setEditProduct }) => {
  const [editForm, setEditForm] = useState(product)

  const saveEditForm = (e) => {
    try {
      e.preventDefault()
      setEditForm((prev) => ({
        ...prev,
        price: parseFloat(prev.price)
      }))
      // ShopListService.createNewShopItem({ groupId, data: formData })
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong.')
    } finally {
      toast.success('Shop item created successfully!')
    }
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setEditForm((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      setEditForm((prev) => ({
        ...prev,
        imagePayload: {
          imageString: reader.result.split(',')[1],
          encoding: 'base64',
          name: file.name
        }
      }))
    }
    reader.readAsDataURL(file)
  }

  const cancelEdit = () => {
    setEditProduct(null)
  }

  return (
    product && (
      <div>
        <PageHeader title='Shop List' hideSubgroupChip />
        <Card sx={{ maxWidth: 500, mx: 'auto', p: 3, boxShadow: 3, borderRadius: 2 }}>
          <CardContent>
            <Typography variant='h6' gutterBottom>
              Product Form
            </Typography>
            <form onSubmit={saveEditForm}>
              <TextField
                label='Title'
                name='title'
                value={product.title}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />
              <TextField
                label='Price'
                name='price'
                type='number'
                value={product.price}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />
              <FormControlLabel
                control={<Checkbox checked={product.onsale} onChange={handleChange} name='onsale' />}
                label='On Sale'
              />
              <TextField
                label='URL'
                name='url'
                type='url'
                value={product.url}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />

              <TextField
                label='Display Weight'
                name='displayWeight'
                type='displayWeight'
                value={product.displayWeight}
                onChange={handleChange}
                fullWidth
                margin='normal'
                required
              />
              <Typography variant='body2' sx={{ mt: 2 }}>
                Upload Image
              </Typography>
              <input type='file' accept='image/*' onChange={handleImageUpload} style={{ marginTop: 8 }} />
              {product.imageString && (
                <img
                  src={product.imageString}
                  alt='Preview'
                  style={{ marginTop: 10, width: '100%', height: 160, objectFit: 'cover', borderRadius: 8 }}
                />
              )}
              <Button type='submit' variant='contained' color='primary' fullWidth sx={{ mt: 2 }}>
                Update
              </Button>
              <Button onClick={cancelEdit} variant='contained' color='primary' fullWidth sx={{ mt: 2 }}>
                Cancel
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    )
  )
}

export default EditProductForm
