import axios from 'axios'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

function getShopItems (groupId) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }
    return axios.get(`${config.apiUrl}group/${groupId}/shopitems`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

/*
    objectId: shopItem.id,
    url: shopItem.get('url'),
    displayWeight: shopItem.get('weight'),
    imageString: shopItem.get('imageUrl'),
    price: shopItem.get('price'),
    sale: shopItem.get('sale'),
    title: shopItem.get('title')
*/

function createNewShopItem ({ groupId, data }) {
  console.log(data)
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }
    const url = `${config.apiUrl}group/${groupId}/shopitems`
    console.log('url', url)

    return axios.post(url, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

export const ShopListService = {
  getShopItems,
  createNewShopItem
}
